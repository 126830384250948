import { RadixNetwork } from '@radixdlt/radix-dapp-toolkit';

interface Config {
  applicationName: string;
  crumbsupUrl: string;
  crumbsToken: string;
  daoCreationFee: number;
  proposalCreationFee: number;
  networkId: number;
  radixDashboardUrl: string;
  radixGatewayUrl: string;
  dAppDefinitionAddress: string;
  componentAddress: string;
  daoResourceAddress: string;
  proposalResourceAddress: string;
  daoAdminBadgeAddress: string;
  voteNftAddress: string;
}

export function isUiDevelopmentServer(): boolean {
  return window.location.href.startsWith('http://localhost:3333');
}

function isLocalServer(): boolean {
  return window.location.href.startsWith('http://localhost:8080');
}

function isTestServer(): boolean {
  return window.location.href.startsWith('https://stokenet.crumbsup.de');
}

export function isTestEnvironment(): boolean {
  return isUiDevelopmentServer() || isLocalServer() || isTestServer();
}

export function getConfig(): Config {
  if (isTestEnvironment()) {
    return {
      applicationName: 'CrumbsUp Stokenet',
      crumbsupUrl: 'https://stokenet.crumbsup.de',
      crumbsToken: 'resource_tdx_2_1thdl0gfmsq3ppckesh3l3caljyzmqwk86dh9xed0n2lv6e9wsjr64z',
      daoCreationFee: 100000.0,
      proposalCreationFee: 0.0,
      networkId: RadixNetwork.Stokenet,
      radixDashboardUrl: 'https://stokenet-dashboard.radixdlt.com',
      radixGatewayUrl: 'https://stokenet.radixdlt.com',
      dAppDefinitionAddress: 'account_tdx_2_12y2duzgvk53lywfjatq654z3renk8galv7g2wkha32v0lnrhc2jway',
      componentAddress: 'component_tdx_2_1cz8sgsr6nu95yut0l2erhjrp3uzuddg896aecqe4tty6y6wwyvgfxa',
      daoResourceAddress: 'resource_tdx_2_1ng4f733wc44xrj5qer63vg7aglqd8tvry32ncvl9cq6tvke6el7889',
      proposalResourceAddress: 'resource_tdx_2_1ntp2nv0uw7ysuzhsz8258zqtm7n9eutqv7qm4pdw6t44rjk08kq25j',
      daoAdminBadgeAddress: 'resource_tdx_2_1ntd6g3j8py3plgs7d7dl9umlra9q60yw8ha8uc7zcqpry2n6w3kzj9',
      voteNftAddress: 'resource_tdx_2_1ntgz0pnly2v3c42hlfw2k2hhqkxvht4xjqps3efhffsx5rz37ul5cu',
    };
  }

  return {
    applicationName: 'CrumbsUp',
    crumbsupUrl: 'https://crumbsup.io',
    crumbsToken: 'resource_rdx1t5xg95m0mhnat0wv59ed4tzmevd7unaezzm04f337djkp8wghz2z7e',
    daoCreationFee: 100000.0,
    proposalCreationFee: 0.0,
    networkId: RadixNetwork.Mainnet,
    radixDashboardUrl: 'https://dashboard.radixdlt.com',
    radixGatewayUrl: 'https://mainnet.radixdlt.com',
    dAppDefinitionAddress: 'account_rdx169dlzydn4f2pekcd2an2nj0506g9v7fytp9a4thkw6secy7zjt0zg2',
    componentAddress: 'component_rdx1cpupsxrv4y36n46d0d9elev9qwjw8p4up58h6ll6yff5p8sh20qckm',
    daoResourceAddress: 'resource_rdx1nfrc5swndv3dhntzzc2zfzpttmx57y9sfk3ajmldqpey5trvwm8p8z',
    proposalResourceAddress: 'resource_rdx1n2drsjp9fagqhn90jz2lwfy72wa00ka77a8fufk06udltqcykxtz0r',
    daoAdminBadgeAddress: 'resource_rdx1n2dp5tgpqvtdu3keznue70fh8wcgmn40t5tz3er84cwm5fum38jrt3',
    voteNftAddress: 'resource_rdx1ntra9m8nevh0njvaqfrkvy6erzdwclzqjnnjzvvc3swvufv6y27lwy',
  };
}
